// src/pages/LandingPage.tsx

import React from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import USPSection from "../components/UniqueSellingPropositions";
import VillainSection from "../components/VillainSection";
import ProcessPlanSection from "../components/ProcessPlan";
import CTASection from "../components/CallToAction";
import Footer from "../components/Footer";
import {
  PenTool,
  Mail,
  Clipboard,
  Tool,
  Inbox,
  MessageSquare,
} from "react-feather";

const LandingPage: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection
        headline="Unlock Efficiency with AI-Driven Solutions"
        subheadline="Let custom AI agents handle repetitive tasks, automated workflows drive seamless integration, and intelligent solutions keep your business ahead of the competition."
        ctaText="Schedule a Call"
        imageSrc="img/icons/Projekt.svg"
      />
      <USPSection
        sectionTitle="Why We’re Your AI Transformation Partner"
        sectionDescription="Leverage over a decade of software development expertise, applied to meet your unique business challenges. Our solutions empower you to work smarter, streamline processes, and stay ahead in the digital era."
        usps={[
          {
            title: "Custom AI Solutions",
            description:
              "Tailored AI solutions designed to match your business needs—whether it's AI agents or automated workflows, we've got you covered.",
            icon: "img/icons/chatbot.svg",
          },
          {
            title: "Agility and Expertise",
            description:
              "Our expert team delivers flexible solutions, from AI-powered Q&A systems to fully automated business processes, built to evolve with your goals.",
            icon: "img/icons/agile.svg",
          },
          {
            title: "End-to-End Service",
            description:
              "From setup to full integration, we provide comprehensive support to ensure a smooth, transformative transition with measurable impact.",
            icon: "img/icons/goal.svg",
          },
        ]}
      />
      <VillainSection
        sectionTitle="Transform Your Business with AI-Driven Solutions"
        sectionSubtitle="Turning Challenges into Growth Opportunities"
        sectionText="Overcome key issues and unlock potential with tailored AI solutions that streamline productivity and fuel your business growth."
        challenges={[
          {
            name: "AI-Generated Marketing Copy",
            description:
              "Streamline content creation with AI-generated copy for blogs, social media, and more.",
            link: "/blog/post?id=content-creation-for-marketing",
            icon: <PenTool />,
          },
          {
            name: "Personalized Sales Outreach Messages",
            description:
              "Increase conversion rates with AI-tailored sales messages that resonate with your prospects.",
            link: "/blog/post?id=enhance-your-outreach-with-ai-driven-sales-solutions",
            icon: <Mail />,
          },
          {
            name: "Agile Story Refinement for Dev Teams",
            description:
              "Stay focused and efficient with AI-assisted backlog grooming and story prioritization for your dev team.",
            link: "/blog/post?id=ticket-refinement-for-software-development",
            icon: <Clipboard />,
          },
          {
            name: "Automated Test Case Generation",
            description:
              "Accelerate your QA process with AI-driven test cases and scripts, tailored to your project’s needs.",
            link: "/blog/post?id=test-generation-for-software-development",
            icon: <Tool />,
          },
          {
            name: "AI-Powered Email Management",
            description:
              "Enhance productivity by automating email sorting, categorization, and responses with AI.",
            link: "/blog/post?id=ai-email-agent",
            icon: <Inbox />,
          },
          {
            name: "HR Support Chatbot",
            description:
              "Automate HR tasks like onboarding and FAQs with AI-powered chatbots.",
            link: "/blog/post?id=chatbot-for-hr-processes",
            icon: <MessageSquare />,
          },
        ]}
      />

      <ProcessPlanSection
        sectionTitle="Our Approach"
        sectionDescription="By analyzing your existing processes, we identify automation opportunities, define the MVP scope, build and test it, and integrate it for full-scale deployment."
        steps={[
          {
            name: "Process Analysis",
            description:
              "We analyze your processes to pinpoint where AI and automations can deliver the most value.",
            icon: "img/icons/File Searching Illustration.svg",
          },
          {
            name: "Define MVP Scope",
            description:
              "Together, we define a clear MVP scope that focuses on your most pressing challenges.",
            icon: "img/icons/Projekt.svg",
          },
          {
            name: "Build MVP",
            description:
              "Our team quickly develops and tests an MVP designed to transform key areas.",
            icon: "img/icons/Pair Programming Illustration.svg",
          },
          {
            name: "Integrate and Harden",
            description:
              "We seamlessly integrate the MVP into your operations and prepare it for full-scale production.",
            icon: "img/icons/Maker Launch.svg",
          },
        ]}
      />

      <CTASection
        headline="Elevate Your Business with AI.\nSchedule a Free Consultation Call."
        subheadline="Discover how AI can transform your business processes, boost productivity, and give you a competitive edge."
        ctaText="Schedule a Call"
      />
      <Footer />
    </div>
  );
};

export default LandingPage;
