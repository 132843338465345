// src/components/ProcessPlanSection.tsx

import React from "react";

interface Step {
  name: string;
  description: string;
  icon: string;
}

interface ProcessPlanSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  steps: Step[];
}

const ProcessPlanSection: React.FC<ProcessPlanSectionProps> = ({
  steps,
  sectionTitle,
  sectionDescription,
}) => {
  return (
    <div className="bg-gray-50 pt-20 pb-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {sectionTitle}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl">
            {sectionDescription}
          </p>
        </div>
        <div className="mx-auto mt-20 max-w-2xl lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
            {steps.map((step, index) => (
              <div key={index} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-20 flex mx-auto items-center justify-center rounded-l">
                    <img
                      src={step.icon}
                      alt={step.name}
                      className="h-40 w-40 text-white"
                    />
                  </div>
                  {step.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{step.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ProcessPlanSection;
