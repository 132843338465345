import ContactForm from "../components/Contactform";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect } from 'react';

export default function Contact() {
  useEffect(() => {
    document.title = "KTR Studio - Contact";
  }, []);

  return (
    <>
      <Header />
      <div className="bg-gray-50 min-h-screen flex flex-col justify-between">
        <div className=" px-6 py-16 sm:py-16 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Get in Touch with Us
            </h1>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              Have questions or want to learn more about our AI solutions? Fill out the form below, and we'll get back to you as soon as possible.
            </p>
          </div>

          <div className="mx-auto mt-16 max-w-xl sm:mt-20">
            <ContactForm />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
