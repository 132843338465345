import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect } from 'react';

export default function About() {
  useEffect(() => {
    document.title = "KTR Studio - Imprint";
  }, []);

  return (
    <>
      <div className="min-h-screen flex flex-col justify-between bg-gray-50">
        <Header />
        <div className="flex-grow flex flex-col items-center justify-center">
          <div className="text-center max-w-3xl px-6 py-16 md:py-24">
            <h1 className="text-4xl font-bold text-gray-900 mb-8">Imprint</h1>
            <p className="text-lg leading-8 text-gray-600 mb-8">
              KTR Solutions GmbH<br />
              Panoramastraße 28 / 1<br />
              71691 Freiberg am Neckar<br />
              Baden-Württemberg<br />
              Germany
            </p>
            <p className="text-lg leading-8 text-gray-600 mb-8">
              Phone: (+49) 15736503614<br />
              Email: info@ktr-solutions.com
            </p>
            <p className="text-lg leading-8 text-gray-600">
              Registergericht: Amtsgericht Stuttgart <br />
              Handelsregisternummer: HRB 785755
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
