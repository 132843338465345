import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BACKEND_BASE_URL } from "../config";

export default function ContactForm() {
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [message, setMessage] = useState("");
  // const [company, setCompany] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");

  async function sendEmail() {
    try {
      await axios.post(`${BACKEND_BASE_URL}/integration/contact`, {
        firstname,
        lastname,
        email,
        // company,
        // phoneNumber,
        message
      });
      toast.success("Your message was sent!");
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error("Something went wrong, please reach out by mail!");
      }
    }
  }

  return (
    <form
      className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2"
      onSubmit={(e) => {
        e.preventDefault();
        sendEmail();
      }}
    >
      <div>
        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
          First name
        </label>
        <div className="mt-2.5">
          <input
            id="first-name"
            name="first-name"
            type="text"
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="given-name"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            required
          />
        </div>
      </div>

      <div>
        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
          Last name
        </label>
        <div className="mt-2.5">
          <input
            id="last-name"
            name="last-name"
            type="text"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="family-name"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            required
          />
        </div>
      </div>

      {/* <div className="sm:col-span-2">
        <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
          Company
        </label>
        <div className="mt-2.5">
          <input
            id="company"
            name="company"
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            autoComplete="organization"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            required
          />
        </div>
      </div> */}

      <div className="sm:col-span-2">
        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
          Email
        </label>
        <div className="mt-2.5">
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            required
          />
        </div>
      </div>

      <div className="sm:col-span-2">
        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
          Message
        </label>
        <div className="mt-2.5">
          <textarea
            id="message"
            name="message"
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            required
          />
        </div>
      </div>

      <div className="mt-10 sm:col-span-2">
        <button
          type="submit"
          className="block w-full rounded-md bg-blue px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Send Message
        </button>
      </div>
    </form>
  );
}
