// src/pages/SoftwareDevelopmentPage.tsx

import React from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import USPSection from "../components/UniqueSellingPropositions";
import PainPointsSection from "../components/VillainSection";
import ProcessPlanSection from "../components/ProcessPlan";
import CTASection from "../components/CallToAction";
import Footer from "../components/Footer";

const SoftwareDevelopmentPage: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection
        headline="Beschleunige deine Softwareentwicklung mit KI-Agenten"
        subheadline="Spare bis zu 60 % Zeit bei der Ticket-Erstellung und 70 % bei der Test-Erstellung – nahtlos integriert in deine Tools."
        ctaText="Demo nutzen"
        imageSrc="/img/icons/Code Inspection.svg"
      />
      <USPSection
        sectionTitle="Dein Entwicklungsteam effizienter machen"
        sectionDescription="Mit unseren KI-gestützten Lösungen sparst du Zeit und Aufwand bei der Ticket- und Test-Erstellung. Unsere Tools integrieren sich nahtlos in deine bestehenden Systeme und unterstützen dich dabei, qualitativ hochwertige Software schneller bereitzustellen."
        usps={[
          {
            title: "Automatisierte Ticket-Erstellung",
            description:
              "Übersetze deine Ideen mit minimalem Aufwand in klar definierte Stories im richtigen Format.",
            icon: "img/icons/Code Inspection.svg",
          },
          {
            title: "Effiziente Test-Erstellung",
            description:
              "Generiere automatisierte Tests, die sofort einsatzbereit sind, und reduziere den Aufwand um bis zu 70 %.",
            icon: "img/icons/test.svg",
          },
          {
            title: "Nahtlose Integration",
            description:
              "Unsere Lösungen integrieren sich reibungslos in Jira und andere Task-Management-Tools.",
            icon: "img/icons/integration.svg",
          },
        ]}
      />
      {/* <PainPointsSection
        title="Gemeinsame Herausforderungen in der Softwareentwicklung"
        painPoints={[
          {
            title: "Unklare Tickets und Stories",
            description:
              'Jira-Tickets mit "To Be Defined" verschwenden Zeit und verursachen Frustration im Team.',
            icon: "img/icons/unclear-tickets.svg",
          },
          {
            title: "Aufwändige Test-Erstellung",
            description:
              "Tests zu schreiben ist mühsam und nimmt Zeit weg von der Entwicklung neuer Features.",
            icon: "img/icons/time-consuming-tests.svg",
          },
          {
            title: "Fehleranfällige Software durch fehlende Tests",
            description:
              "Ungetestete Software ist anfällig für Fehler und beeinträchtigt Zuverlässigkeit und Nutzerzufriedenheit.",
            icon: "img/icons/unreliable-software.svg",
          },
        ]}
      /> */}
      <ProcessPlanSection
        sectionTitle="Effiziente Arbeitsweise mit KI"
        sectionDescription="Nutze die Vorteile unserer KI, um Ideen schnell in umsetzbare Tickets und Tests zu übersetzen. Von der Eingabe deiner Anforderungen bis zur nahtlosen Integration in deine Tools – wir machen den Prozess effizient und einfach."
        steps={[
          {
            name: "Idee eingeben",
            description:
              "Teile mit, was umgesetzt werden soll – in natürlicher Sprache.",
            icon: "img/icons/idea-input.svg",
          },
          {
            name: "KI verarbeitet",
            description:
              "Unsere KI versteht deine Absicht und schlägt vollständige Tickets und Tests vor.",
            icon: "img/icons/ai-processing.svg",
          },
          {
            name: "Ergebnis nutzen",
            description:
              "Übernimm die Vorschläge direkt in Jira oder dein bevorzugtes Task-Management-Tool.",
            icon: "img/icons/output.svg",
          },
        ]}
      />
      <CTASection
        headline="Bereit, effizienter zu entwickeln und Fehler zu minimieren?"
        subheadline="Erfahre selbst, wie KTR Solutions dein Team unterstützen kann. Nutze unsere Demo und überzeuge dich von den Vorteilen."
        ctaText="Demo nutzen"
      />
      <Footer />
    </div>
  );
};

export default SoftwareDevelopmentPage;
