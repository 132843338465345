// src/components/VillainSection.tsx

import React from "react";

interface Challenge {
  name: string;
  description: string;
  link: string;
  icon: React.ReactNode;
}

interface VillainSectionProps {
  sectionTitle: string;
  sectionSubtitle?: string;
  sectionText?: string;
  challenges: Challenge[];
}

const VillainSection: React.FC<VillainSectionProps> = ({
  sectionTitle,
  sectionSubtitle,
  sectionText,
  challenges,
}) => {
  return (
    <div className="overflow-hidden bg-gray-50" id="challenges">
      <div className="mx-auto pt-18 pb-20 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0 text-center lg:text-left">
          <h2 className="text-base font-semibold text-blue">{sectionTitle}</h2>
          <h3 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {sectionSubtitle}
          </h3>
          <p className="mt-6 text-lg leading-8 text-gray-600">{sectionText}</p>
        </div>
        <div className="mt-20 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
          {challenges.map((challenge, index) => (
            <div key={index} className="flex flex-col">
              <div className="text-blue-500 text-4xl">{challenge.icon}</div>
              <a href={challenge.link}>
                <dt className="mt-4 text-base font-semibold leading-7 text-gray-900">
                  {challenge.name}
                </dt>

                <dd className="mt-1 text-base leading-7 text-gray-600">
                  {challenge.description}
                </dd>
                <div className="mt-2 text-blue text-sm font-semibold leading-6">
                  Mehr erfahren →
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VillainSection;
