// src/components/HeroSection.tsx

import React from "react";

interface HeroSectionProps {
  headline: string;
  subheadline: string;
  ctaText: string;
  imageSrc: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  headline,
  subheadline,
  ctaText,
  imageSrc,
}) => {
  return (
    <div className="bg-gray-50">
      <div className="relative isolate overflow-hidden pt-8">
        <div className="mx-auto max-w-7xl px-6 lg:pt-32 pb-24 sm:pb-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:gap-x-8">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              {headline}
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 order-2 lg:order-1">
              <p className="text-lg leading-8 text-gray-600">{subheadline}</p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: document.body.scrollHeight,
                      behavior: "smooth",
                    });
                  }}
                  className="rounded-md bg-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-800"
                >
                  {ctaText}
                </a>
                <a
                  href="#challenges"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="order-1 lg:order-2">
              <img
                alt="Illustration"
                src={imageSrc}
                className="w-2/3 rounded-2xl object-cover lg:-mt-16 mt-8 mx-auto my-auto lg:max-w-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
