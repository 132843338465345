import { motion } from 'framer-motion';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { GraphQLClient, gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import BlogPostSection from './BlogPostSection';

// Define TypeScript interfaces for the post data structure
interface Author {
  name: string;
  role: string;
  imageUrl: string;
}

interface Category {
  title: string;
  href: string;
}

interface Tag {
  id: string;
  name: string;
}

interface Post {
  id: string;
  title: string;
  href: string;
  description: string;
  date: string;
  datetime: string;
  author: Author;
  tags: Tag[];
}

interface CategorizedPosts {
  usecases: Post[];
  research: Post[];
}

// Fetching the blog posts from Contentful using GraphQL
async function blogquery(): Promise<Post[]> {
  const endpoint = 'https://graphql.contentful.com/content/v1/spaces/b8exbjlcecvz';
  // const query = gql`
  //   query GetBlogPosts {
  //     aiPostCollection {
  //       items {
  //         title
  //         description
  //         urlName
  //         date
  //         author {
  //           name
  //           role
  //           imageUrl
  //         }
  //       }
  //     }
  //   }
  // `;
  const query = gql`
  query GetBlogPostByUrlName{
      aiPostCollection(limit:50, order:[date_DESC]){
          items{
            title
            description
            urlName
            date
            contentfulMetadata {
              tags {
                  id
                  name
              }
            }
          }
        }
      }
  `

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      Authorization: 'Bearer Y9k68HidHsj7hfIfxud23KXx6sjRnOZX8NTlM92rgwc',
    },
  });

  const data = await graphQLClient.request(query);
  return data.aiPostCollection.items.map((item: any) => ({
    id: item.urlName,
    title: item.title,
    href: `/blog/post?id=${item.urlName}`,
    description: item.description,
    date: item.date || 'Unknown date',
    datetime: item.date ? new Date(item.date).toISOString() : '',
    category: { title: item.category || 'General', href: '#' },
    author: {
      name: item.author?.name || 'Unknown author',
      role: item.author?.role || 'Author',
      imageUrl: item.author?.imageUrl || 'https://via.placeholder.com/256',
    },
    tags: item.contentfulMetadata.tags || [],
  }));
}
export default function AIBlog() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<{ usecases: Post[], research: Post[] }>({ usecases: [], research: [] });

  useEffect(() => {
    blogquery()
      .then((data) => {
        const categorizedPosts = data.reduce<CategorizedPosts>((acc, post) => {
          if (post.tags.some(tag => tag.id === "usecase")) {
            acc.usecases.push(post);
          } else if (post.tags.some(tag => tag.id === "research")) {
            acc.research.push(post);
          }
          return acc;
        }, { usecases: [], research: [] });
  
        setPosts(categorizedPosts);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (isLoading) return <span>Loading...</span>;

  return (
    <>
      <Header />
      <div className="py-16 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <BlogPostSection
            title="Usecases"
            description="Discover how AI-powered solutions are transforming businesses across industries."
            posts={posts.usecases}
          />
          <BlogPostSection
            title="AI Innovation Blog"
            description="All about latest AI advancements and how they can be leveraged for your business"
            posts={posts.research}
            className="mt-40"
          />
          {/* TODO: Pagination */}
        </div>
      </div>
      <Footer />
    </>
  );
}

