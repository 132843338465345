import React, { useState } from "react";

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
}

const HubSpotForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState<
    "success" | "error" | ""
  >("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const portalId = "47611510";
    const formGuid = "e983c6a5-0351-40a2-9d11-58d07daefdf3";
    const payload = {
      fields: [
        { name: "email", value: formData.email },
        { name: "firstname", value: formData.firstName },
        { name: "lastname", value: formData.lastName },
        { name: "company", value: formData.companyName },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setSubmissionStatus("success");
      } else {
        const errorData = await response.json();
        console.error("Submission error:", errorData);
        setSubmissionStatus("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus("error");
    }
  };

  return (
    <div className="w-11/12 mx-auto p-6 rounded-lg">
      {submissionStatus === "success" && (
        <p className="text-green-600 text-center mb-8">
          Thanks for your submission, we will get in touch with you soon!
        </p>
      )}
      {submissionStatus === "error" && (
        <p className="text-red-600 text-center mb-4">
          There was an error submitting the form pleas contact us via the
          contact page.
        </p>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* First Name */}
          <div>
            <label
              htmlFor="firstName"
              className="block text-md mb-3 font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {/* Last Name */}
          <div>
            <label
              htmlFor="lastName"
              className="block text-md mb-3 font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-md mb-3 font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {/* Company Name */}
          <div>
            <label
              htmlFor="companyName"
              className="block text-md mb-3 font-medium text-gray-700"
            >
              Company Name
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="py-6">
          <button
            type="submit"
            className="flex mx-auto py-2 px-4 text-white text-base font-semibold tracking-wider rounded shadow bg-blue hover:bg-sky-800"
          >
            Book Call Now!
          </button>
        </div>
      </form>
    </div>
  );
};

export default HubSpotForm;
