import { useEffect } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import useRoutingInstrumentation from "react-router-v6-instrumentation";



import { BrowserTracing } from "@sentry/tracing";
import { init } from "@sentry/react";
import { RELEASE_VERSION, SENTRY_DSN } from "./config";
import AnimatedRoutes from "./components/AnimatedRoutes";



function App() {

  // Initialize Sentry with the browser tracing integration.
  const routingInstrumentation = useRoutingInstrumentation();

  
  useEffect(() => {
    const browserTracing = new BrowserTracing({
      routingInstrumentation,
    });
    init({
      dsn: SENTRY_DSN,
      release: RELEASE_VERSION,
      tracesSampleRate: 1.0,
      integrations: [browserTracing],
    });
  }, [routingInstrumentation]);

  return (
    <>
      <ToastContainer />
            <AnimatedRoutes/>
    </>
  );
}

export default App;
