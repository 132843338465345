import React from 'react';

interface Tag {
id: string;
name: string;
}

interface Post {
id: string;
title: string;
description: string;
date: string;
href: string;
tags: Tag[];
}

interface BlogPostSectionProps {
title: string;
description: string;
posts: Post[];
className?: string;
}

export default function BlogPostSection({ title, description, posts, className = '' }: BlogPostSectionProps) {
return (
<div className={className}>
    <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">{description}</p>
    </div>
    <div
        className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {posts.map((post) => (
        <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
            <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.date} className="text-gray-500">
                    {new Date(post.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    })}
                </time>
                {post.tags.map((tag) => (
                <a key={tag.id} href="#"
                    className="relative z-10 rounded-full bg-gray-100 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-200">
                    {tag.name}
                </a>
                ))}
            </div>
            <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                    </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
            </div>
            <div className="relative mt-8 flex items-center gap-x-4">
                <img alt="" src={require('../../img/justin.jpg')}
                    className="h-10 w-10 rounded-full bg-gray-50 object-cover" />
                <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                        <a href="#">
                            <span className="absolute inset-0" />
                            Justin Kater
                        </a>
                    </p>
                    <p className="text-gray-600">Founder & CEO</p>
                </div>
            </div>
        </article>
        ))}
    </div>
</div>
);
}