import { useNavigate } from "react-router-dom";
import { Logo20 } from "../components/Logo";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect } from 'react';

export default function NotFound() {
let navigate = useNavigate();

useEffect(() => {
document.title = "KTR Studio - Page not found"
}, []);
return (
<>
  <Header />

  <div className="flex flex-col h-screen justify-between">
    <div className="flex justify-center px-4 sm:px-6 lg:px-8 bg-gray-50 mt-20 lg:mt-40">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center">
          <Logo20 />
          <h1 className="mt-6 text-center text-3xl font-extrabold font-mono text-gray-900">
            404 Not Found
          </h1>
          <p className="mt-2 text-center text-sm text-gray-600">
            We don't know what your looking for, but it's definitely not here...
          </p>
          <div className="mt-5">
            <span className="cursor-pointer text-blue" onClick={()=> navigate(-1)}
              >
              Go Back
            </span>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>

</>
);
}