// src/components/USPSection.tsx

import React from "react";

interface USP {
  title: string;
  description: string;
  icon: string;
}

interface USPSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  usps: USP[];
}

const USPSection: React.FC<USPSectionProps> = ({
  usps,
  sectionTitle,
  sectionDescription,
}) => {
  return (
    <section className="pt-16 pb-24 md:pt-20 md:pb-48">
      <div className="container mx-auto text-center max-w-7xl px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800">
          {sectionTitle}
        </h2>
        <p className="text-base md:text-lg mt-6 max-w-xl mx-auto text-gray-600">
          {sectionDescription}
        </p>
        <div className="mt-16 md:mt-20 grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-16">
          {usps.map((usp, index) => (
            <div key={index}>
              <img
                src={usp.icon}
                alt={usp.title}
                className="w-32 h-32 mx-auto"
              />
              <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mt-6">
                {usp.title}
              </h3>
              <p className="mt-4 md:mt-6 text-gray-600">{usp.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default USPSection;
