import { useRef, useEffect, useState } from "react";
import Header from "../../components/Header";
import { GraphQLClient, gql } from "graphql-request";
import {
  documentToReactComponents,
  Options,
  RenderNode,
} from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  Document,
  Inline,
  INLINES,
  MARKS,
  Text,
  Node,
  Block,
} from "@contentful/rich-text-types";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";
import CallToAction from "../../components/CallToAction";
import slugify from "slugify";

// Type Guards
function isText(node: Node): node is Text {
  return node.nodeType === "text";
}

function isBlock(node: Node): node is Block {
  return Object.values(BLOCKS).includes(node.nodeType as BLOCKS);
}

function isInline(node: Node): node is Inline {
  return Object.values(INLINES).includes(node.nodeType as INLINES);
}

// Function to extract plain text from header nodes
function getPlainTextFromHeader(contentNodes: Node[]): string {
  return contentNodes.reduce((acc: string, current: Node) => {
    if (isText(current)) {
      return acc + current.value;
    } else if (isBlock(current) || isInline(current)) {
      return acc + getPlainTextFromHeader(current.content);
    }
    return acc;
  }, "");
}

// Function to extract headers from the Rich Text content
function getHeadersFromRichText(richText: Document) {
  const headers = (content: Node) =>
    content.nodeType === BLOCKS.HEADING_1 ||
    content.nodeType === BLOCKS.HEADING_2 ||
    content.nodeType === BLOCKS.HEADING_3 ||
    content.nodeType === BLOCKS.HEADING_4;

  return richText.content.filter(headers).map((heading: Block) => {
    const plainText = getPlainTextFromHeader(heading.content);

    // Determine heading level based on the nodeType
    const headingLevel =
      heading.nodeType === BLOCKS.HEADING_1
        ? 1
        : heading.nodeType === BLOCKS.HEADING_2
        ? 2
        : heading.nodeType === BLOCKS.HEADING_3
        ? 3
        : heading.nodeType === BLOCKS.HEADING_4
        ? 4
        : 0;

    return {
      text: plainText,
      href: `#${slugify(plainText)}`,
      level: headingLevel, // Add heading level for later use in TOC
    };
  });
}

// Table of Contents component
const TableOfContents = ({
  headers,
  activeId,
}: {
  headers: { text: string; href: string; level: number }[];
  activeId: string; // Receive the activeId from the parent
}) => {
  return (
    <div className="ml-20 mr-4">
      <h5 className="text-slate-900 font-semibold mb-4 text-sm leading-6">
        On this page
      </h5>
      <ul className="text-slate-700 text-sm leading-6">
        {headers.map((item, i) => (
          <li
            key={i}
            className={item.level > 1 ? `ml-${(item.level - 1) * 4}` : ""}
          >
            <a
              href={item.href}
              className={`group flex items-start py-1 font-medium ${
                item.href === `#${activeId}` ? "text-blue-500" : "text-gray-600"
              } hover:text-blue`}
            >
              {item.level > 1 && (
                <svg
                  width="3"
                  height="24"
                  viewBox="0 -9 3 24"
                  className="mr-2 text-slate-400 overflow-visible group-hover:text-slate-600"
                >
                  <path
                    d="M0 0L3 3L0 6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              )}
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

async function blogquery() {
  const endpoint =
    "https://graphql.contentful.com/content/v1/spaces/b8exbjlcecvz";

  const queryParams = new URLSearchParams(window.location.search);

  const postname = queryParams.get("id");
  const urlName = postname;

  const query = gql`
    query GetAIPostByUrlName($urlName: String!) {
      aiPostCollection(where: { urlName: $urlName }, limit: 1) {
        items {
          title
          date
          content {
            json
            links {
              entries {
                inline {
                  sys {
                    id
                  }
                }
                block {
                  sys {
                    id
                  }
                }
              }
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                  description
                }
              }
            }
          }
          description
        }
      }
    }
  `;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      Authorization: "Bearer Y9k68HidHsj7hfIfxud23KXx6sjRnOZX8NTlM92rgwc",
    },
    body: JSON.stringify({
      query,
      variables: { urlName },
    }),
  });
  const data = await graphQLClient.request(query);
  return data.aiPostCollection.items["0"];
}

function renderOptions(
  responselinks: {
    assets: { block: any };
    entries: { block: any; inline: any };
  },
  collectHeading: (element: HTMLElement | null) => void
): Options {
  // create an asset map
  const assetMap = new Map();
  // loop through the assets and add them to the map
  for (const asset of responselinks.assets.block) {
    assetMap.set(asset.sys.id, asset);
  }
  // create an entry map
  const entryMap = new Map();
  // loop through the block linked entries and add them to the map
  for (const entry of responselinks.entries.block) {
    entryMap.set(entry.sys.id, entry);
  }

  // loop through the inline linked entries and add them to the map
  for (const entry of responselinks.entries.inline) {
    entryMap.set(entry.sys.id, entry);
  }
  // create an entry block map
  const entryBlockMap = new Map();

  // loop through the block entries and add them to the map
  for (const entry of responselinks.entries.block) {
    entryBlockMap.set(entry.sys.id, entry);
  }

  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode) => <b>{text}</b>,
      [MARKS.ITALIC]: (text: React.ReactNode) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (text: React.ReactNode) => <u>{text}</u>,
      [MARKS.CODE]: (text: React.ReactNode) => <code>{text}</code>,
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: (node: Block | Inline, children: React.ReactNode) =>
        children,
      [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => (
        <p className="text-lg">
          {children}
          <br />
          <br />
        </p>
      ),
      [BLOCKS.HEADING_1]: (node: Block | Inline, children: React.ReactNode) => {
        const plainText = getPlainTextFromHeader(node.content);
        const id = slugify(plainText);

        return (
          <h1
            id={id}
            ref={collectHeading}
            className="text-4xl mb-10 mt-8 font-bold md:max-w-[33vw]"
          >
            {children}
          </h1>
        );
      },
      [BLOCKS.HEADING_2]: (node: Block | Inline, children: React.ReactNode) => {
        const plainText = getPlainTextFromHeader(node.content);
        const id = slugify(plainText);

        return (
          <h2
            id={id}
            ref={collectHeading}
            className="text-3xl my-8 font-semibold md:max-w-[33vw]"
          >
            {children}
          </h2>
        );
      },
      [BLOCKS.HEADING_3]: (node: Block | Inline, children: React.ReactNode) => {
        const plainText = getPlainTextFromHeader(node.content);
        const id = slugify(plainText);

        return (
          <h3 id={id} ref={collectHeading} className="text-2xl my-8">
            {children}
          </h3>
        );
      },
      [BLOCKS.HEADING_4]: (node: Block | Inline, children: React.ReactNode) => {
        const plainText = getPlainTextFromHeader(node.content);
        const id = slugify(plainText);

        return (
          <h4 id={id} ref={collectHeading}>
            {children}
          </h4>
        );
      },
      [BLOCKS.LIST_ITEM]: (node: Block | Inline, children: React.ReactNode) => {
        return <li className="list-disc max-w-1/3">{children}</li>;
      },
      [BLOCKS.UL_LIST]: (node: Block | Inline, children: React.ReactNode) => {
        return <ul className="list-disc max-w-1/3">{children}</ul>;
      },
      [BLOCKS.OL_LIST]: (node: Block | Inline, children: React.ReactNode) => {
        return <ol className="list-decimal max-w-1/3">{children}</ol>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (
        node: Block | Inline,
        children: React.ReactNode
      ) => {
        const asset = assetMap.get(node.data.target.sys.id);
        return (
          <img
            src={asset.url}
            alt={asset.description}
            className="mt-10 mb-16"
          />
        );
      },
    },
  };

  return options;
}

export default function AIBlogPost() {
  const [isLoading, setLoading] = useState(false);
  const [date, setDate] = useState<string>();
  const [notFound, setNotFound] = useState(false);
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<Document>();
  const [links, setLinks] = useState<Options>();
  const [headers, setHeaders] = useState<
    { text: string; href: string; level: number }[]
  >([]);
  const [activeId, setActiveId] = useState<string>("");

  const headingElementsRef = useRef<HTMLElement[]>([]);

  function collectHeading(element: HTMLElement | null) {
    if (element && !headingElementsRef.current.includes(element)) {
      headingElementsRef.current.push(element);
    }
  }

  useEffect(() => {
    setLoading(true);
    blogquery()
      .then((data) => {
        if (data) {
          setDate(data.date.substring(0, 10));
          setTitle(data.title);
          document.title = data.title;

          headingElementsRef.current = [];
          const renderedOptions = renderOptions(
            data.content.links,
            collectHeading
          );
          setLinks(renderedOptions);
          setContent(data.content.json);

          const generatedHeaders = getHeadersFromRichText(data.content.json);
          setHeaders(generatedHeaders);

          setLoading(false);
          setNotFound(false);
        } else {
          setLoading(false);
          setNotFound(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (headingElementsRef.current.length === 0) return;

    const callback = (entries: IntersectionObserverEntry[]) => {
      let newActiveId = "";
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          newActiveId = entry.target.id;
        }
      });
      setActiveId(newActiveId); // Set the active heading id when scrolled into view
      console.log(newActiveId);
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px 0px -30% 0px", // Adjust rootMargin as needed to control when to highlight the section
    });

    headingElementsRef.current.forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, [headingElementsRef.current]); // Add headingElementsRef.current as a dependency

  if (isLoading) {
    return (
      <>
        <div className="screen-h">
          <Header />
          <div className="flex flex-col justify-between">
            <div className="flex items-center justify-center bg-grey-50 pt-64">
              <div role="status">{/* Loading Spinner */}</div>
            </div>
            <div className="absolute bottom-0 w-full">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  } else if (notFound) {
    return (
      <>
        <div className="screen-h">
          <Header />
          <div className="flex flex-col justify-between">
            <div className="flex items-center justify-center bg-grey-50 pt-64">
              <div role="status">Blog entry could not be found.</div>
            </div>
            <div className="absolute bottom-0 w-full">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>
          <Header />
          <div className="flex flex-col justify-between">
            <div className="flex  justify-center bg-grey-50">
              <div className="hidden min-w-[20vw]  lg:block mr-20">
                <div className="sticky top-10">
                  <div className="pl-9 mt-10 mb-8">
                    <a
                      href="/blog"
                      className="flex items-center bg-blue-500 px-4 py-2 rounded-md text-blue fill-blue"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        className="h-5 w-5 mr-2"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Back to Overview
                    </a>
                  </div>
                  <TableOfContents headers={headers} activeId={activeId} />
                </div>
              </div>
              <div className="container w-full mx-auto px-4">
                <div className="font-sans">
                  <div className="w-full md:max-w-2xl mt-10 px-4 md:px-6 text-xl text-gray-800 leading-normal">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.3 } }}
                      exit={{ opacity: 0, transition: { duration: 0.3 } }}
                      className="content flex"
                    >
                      {/* Main Content Area */}
                      <div className="w-full">
                        <h1 className="text-center mx-auto text-3xl md:text-3xl font-bold tracking-tight text-black sm:text-4xl mb-10">
                          {title}
                        </h1>
                        {documentToReactComponents(content as Document, links)}
                      </div>
                    </motion.div>
                    <CallToAction
                      headline="Interested in learning more?"
                      subheadline="Discover how we can help you achieve your goals."
                      ctaText="Contact us"
                    />

                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
