// src/components/CTASection.tsx

import React from "react";
import HubspotContactForm from "./HubspotContactForm";
interface CTASectionProps {
  headline: string;
  subheadline: string;
  ctaText: string;
}

const CTASection: React.FC<CTASectionProps> = ({
  headline,
  subheadline,
  ctaText,
}) => {
  const headlineLines = headline.split("\\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < headline.split("\\n").length - 1 && <br />}
    </React.Fragment>
  ));
  return (
    <div className="bg-gray-50">
      <div className="px-6 sm:px-6 sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {headlineLines}
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            {subheadline}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <HubspotContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
